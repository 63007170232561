import Vue from "vue";
import Router from "vue-router";

import Card from "../src/view/Card.vue";
import CardEng from "../src/view/CardEng.vue";

Vue.use(Router);

const routes = [
  {
    path: "/:id",
    name: "Card",
    props: { default: true },
    components: { default: Card },
  },
  {
    path: "/e/:id",
    name: "CardEng",
    props: { default: true },
    components: { default: CardEng },
  },
]

export default routes;

// export default new Router({
//   mode: "history",
//   routes: [
//     {
//       path: "/:id",
//       name: "Card",
//       props: { default: true },
//       components: { default: Card },
//     },
//   ],
// });

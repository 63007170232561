import axios from "axios";
import { api } from "./helper";
const client = axios.create({
  baseURL: api,
  withCredentials: false,
});

const fileClient = axios.create({
  baseURL: api,
  responseType: 'blob',
  withCredentials: false,
});

function GetCard(id) {
  return client.get("GetCard/" + id);
}

function GetProgramPdf(id) {

//   axios({
//     url: api + "ECard/ProgramPdf/" + id,
//     method: 'GET',
//     responseType: 'blob',
// }).then((response) => {
//      var fileURL = window.URL.createObjectURL(new Blob([response.data]));
//      var fileLink = document.createElement('a');
  
//      fileLink.href = fileURL;
//      fileLink.setAttribute('download', 'file.pdf');
//      document.body.appendChild(fileLink);
   
//      fileLink.click();
// });

  return fileClient.get("ECard/ProgramPdf/" + id);
}

function GetCardMoc(id) {
  return {
    CardNumber: "1933094",
    Name: "SALAMOV HAFIZ",
    GroupName: "PLATINUM",
    StartDateStr: "10.01.2022",
    EndDateStr: "09.01.2023",
  };
}

export { GetCard, GetCardMoc, GetProgramPdf };

<template>
  <div class="vw-100 vh-100 bg-light">
    <div v-if="found === 1" class="main d-flex justify-content-center" style="height: 600px">
      <div class="ecard border shadow-sm position-relative">
        <div class="header-div">
          <img src="/Banner white.png" class="header-img" />
        </div>
        <div
          class="position-absolute inside"
          style="background-color: transparent"
        >
          <!-- Full name -->
          <div class="row" style="margin-top: -10px">
            <div class="col-12">
              <div class="text-left">
                <span class="ecard-title">Insured</span>
              </div>
              <div class="text-left title-margin">
                <span class="sur-name">
                  {{ surname }}
                </span>
                <span class="full-name">
                  {{ name }}
                </span>
              </div>
            </div>
          </div>
          <!-- Card number -->
          <div class="row" style="margin-top: 15px">
            <div class="col-12">
              <table style="width: 100%">
                <tr>
                  <td class="icon-cell">
                    <span>
                      <i class="fa fa-heartbeat" aria-hidden="true"></i>
                    </span>
                  </td>
                  <td>
                    <div class="text-left">
                      <span class="ecard-title">Voluntary Health Insurance</span>
                    </div>
                    <div class="text-left title-margin">
                      <span class="ecard-value">
                        {{ "#" + card.cardNumber }}
                      </span>
                    </div>
                  </td>
                  
                </tr>
              </table>
            </div>
          </div>
          <!-- Program -->
          <div class="row" style="margin-top: 10px">
            <div class="col-12">
              <table style="width: 100%">
                <tr>
                  <td class="icon-cell">
                    <span>
                      <i class="fa fa-file-text" aria-hidden="true"></i>
                    </span>
                  </td>
                  <td>
                    <div class="text-left">
                      <span class="ecard-title">Program</span>
                    </div>
                    <div class="text-left title-margin">
                      <span class="ecard-value">
                        {{ card.groupName }}
                      </span>
                    </div>
                  </td>
                  
                </tr>
              </table>
            </div>
          </div>
          <!-- Period -->
          <div class="row" style="margin-top: 10px">
            <div class="col-12">
              <table style="width: 100%">
                <tr>
                  <td class="icon-cell">
                    <span>
                      <i class="fa fa-calendar" aria-hidden="true"></i>
                    </span>
                  </td>
                  <td>
                    <div class="text-left">
                      <span class="ecard-title">Policy period</span>
                    </div>
                    <div class="text-left title-margin">
                      <span class="ecard-value">
                        {{ card.startDateStr + " - " + card.endDateStr }}
                      </span>
                    </div>
                  </td>
                  
                </tr>
              </table>
            </div>
          </div>

          <!-- Call center -->
          <div class="row" style="margin-top: 25px">
            <div class="col-12">
              <table style="width: 100%">
                <tr>
                  <td class="icon-cell">
                    <span>
                      <i class="fa fa-phone-square" aria-hidden="true"></i>
                    </span>
                  </td>
                  <td>
                    <div class="text-left">
                      <span class="ecard-title">Call center</span>
                    </div>
                    <div class="text-left title-margin" style="padding-bottom: 5px">
                      <a
                        :href="'tel:+994122100103'"
                        style="font-size: 10pt; color: red; font-weight: bold"
                      >
                        (+994) 12 210 0 103</a
                      >
                      <br />
                      <a
                        :href="'tel:+994125260103'"
                        style="font-size: 10pt; color: red; font-weight: bold"
                      >
                        (+994) 12 526 0 103</a
                      >
                    </div>
                  </td>
                  
                </tr>
              </table>
            </div>
          </div>
          
          <!-- Program PDF -->
          <div class="row" style="margin-top: 10px">
            <div class="col-12">
              <table style="width: 100%">
                <tr>
                  <td class="icon-cell">
                    <span>
                      <i class="fa fa-shield" aria-hidden="true"></i>
                    </span>
                  </td>
                  <td>
                    <div
                      class="text-left title-margin"
                    >
                      <a class="ecard-value" style="cursor: pointer" @click="getProgramPdf">
                        Insurance coverage</a
                      >
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>

          <!-- Feedback -->
          <div class="row" style="margin-top: 10px">
            <div class="col-12">
              <table style="width: 100%">
                <tr>
                  <td class="icon-cell">
                    <span>
                      <i class="fa fa-commenting-o" aria-hidden="true"></i>
                    </span>
                  </td>
                  <td>
                    <div
                      class="text-left title-margin"
                    >
                      <a class="ecard-value" style="cursor: pointer" href="mailto: medicalclaims@azersigorta.az">
                        Feedback</a
                      >
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div style="width: 100%; text-align: right">
          <img src="/ecard-tmp.png" style="width: 50%; margin-top: 10px" />
        </div>
      </div>
    </div>
    <div v-if="found === 0" style="text-align: center; padding-top: 20px">
      <i class="fa fa-clock-o fa-4x" style="margin-left: -30px"></i>
      <br>
      <span style="font-weight: bold">Please wait</span>
    </div>
    <div v-if="found === 2" style="text-align: center; padding-top: 20px">
      <i class="fa fa-ban fa-4x" style="color: red; margin-left: -30px"></i>
      <br>
      <span style="font-weight: bold">No data found</span>
    </div>
  </div>
</template>

<script>
import { GetCard, GetProgramPdf, GetCardMoc } from "../services/ecard";
export default {
  props: {
    id: String,
  },
  components: {},
  computed: {
    surname() {
      return this.fullname[0];
    },
    name() {
      return this.fullname[1];
    },
  },
  data() {
    return {
      card: {},
      fullname: [],
      found: 0,
    };
  },
  methods: {
    getCard() {
      this.found = 0;
      GetCard(this.id).then((response) => {
        if (response.data) {
          // console.log(response.data);
          this.card = response.data;
          this.fullname = this.card.name.split(" ");
          this.found = 1;
        } else {
          this.found = 2;
        }
      });
    },
    getCardMoc() {
      this.found = 0;
      this.card = GetCardMoc(this.id);
      this.fullname = this.card.Name.split(" ");
      this.found = 1;
    },

    getProgramPdf() {
      GetProgramPdf(this.id).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", "program-" + this.id + ".pdf");
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
  },
  mounted() {
    this.getCard();
  },
};
</script>


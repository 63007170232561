<template>
  <router-view></router-view>
</template>

<script>
  export default {
    mounted () {
      this.$notify(
        {
          icon: 'nc-icon nc-app'
        })
    }
  }
</script>

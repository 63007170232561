<template>
  <div class="vw-100 bg-light">
    
    <div
      v-if="found === 1"
      class="main d-flex justify-content-center"
      style="height: 660px"
    >
    
      <div class="ecard border shadow-sm position-relative">
        <div class="header-div">
          <img src="header.png" class="header-img" />
        </div>
        <img src="bg.png" class="img-fluid"/>
        <div
          class="position-absolute inside"
          
        >
          <!-- Full name -->
          <div class="row" style="margin-top: 30px">
            <div class="col-12">
              <div class="text-left">
                <span class="ecard-fullanme-title">Sığorta olunan</span>
              </div>
              <div class="text-left title-margin">
                <span class="full-name">
                  {{ card.Name }}
                </span>
              </div>
            </div>
          </div>
          <!-- Card number -->
          <div class="row" style="margin-top: 15px">
            <div class="col-12">
              <table style="width: 100%">
                <tr>
                  <td class="icon-cell">
                    <span>
                      <i class="fa fa-heartbeat" aria-hidden="true"></i>
                    </span>
                  </td>
                  <td>
                    <div class="text-left">
                      <span class="ecard-title">Şəhadətnamə nömrəsi</span>
                    </div>
                    <div class="text-left title-margin">
                      <span class="ecard-value">
                        {{ card.CardNumber }}
                      </span>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <!-- Program -->
          <div class="row" style="margin-top: 10px">
            <div class="col-12">
              <table style="width: 100%">
                <tr>
                  <td class="icon-cell">
                    <span>
                      <i class="fa fa-file-text" aria-hidden="true"></i>
                    </span>
                  </td>
                  <td>
                    <div class="text-left">
                      <span class="ecard-title">Proqram</span>
                    </div>
                    <div class="text-left title-margin">
                      <a href="#" class="ecard-a"> {{ card.Program }}</a>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <!-- Sum insured -->
          <div class="row" style="margin-top: 10px">
            <div class="col-12">
              <table style="width: 100%">
                <tr>
                  <td class="icon-cell">
                    <span>
                      <i class="fa fa-money" aria-hidden="true"></i>
                    </span>
                  </td>
                  <td>
                    <div class="text-left">
                      <span class="ecard-title">Sığorta məbləği</span>
                    </div>
                    <div class="text-left title-margin">
                      <span class="ecard-value">
                        {{ card.SumInsured }}
                      </span>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <!-- Period -->
          <div class="row" style="margin-top: 10px">
            <div class="col-12">
              <table style="width: 100%">
                <tr>
                  <td class="icon-cell">
                    <span>
                      <i class="fa fa-calendar" aria-hidden="true"></i>
                    </span>
                  </td>
                  <td>
                    <div class="text-left">
                      <span class="ecard-title">Sığorta müddəti</span>
                    </div>
                    <div class="text-left title-margin">
                      <span class="ecard-value">
                        {{ card.Period }}
                      </span>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>

          <!-- Call center -->
          <div class="row" style="margin-top: 10px">
            <div class="col-12">
              <table style="width: 100%">
                <tr>
                  <td class="icon-cell">
                    <span>
                      <i class="fa fa-phone-square" aria-hidden="true"></i>
                    </span>
                  </td>
                  <td>
                    <div class="text-left">
                      <span class="ecard-title">Çağrı mərkəzi</span>
                    </div>
                    <div
                      class="text-left title-margin"
                      style="padding-bottom: 5px"
                    >
                      <a :href="'tel:+994123770909'" class="ecard-a">
                        (+994) 12 377 09 09</a
                      >
                      /
                      <a :href="'tel:*0909'" class="ecard-a"> *0909</a>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>

          <!-- Program PDF -->
          <!-- <div class="row" style="margin-top: 25px">
            <div class="col-12">
              <table style="width: 100%">
                <tr>
                  <td class="icon-cell">
                    <span>
                      <i class="fa fa-shield" aria-hidden="true"></i>
                    </span>
                  </td>
                  <td>
                    <div class="text-left title-margin">
                      <a
                        class="ecard-value"
                        style="cursor: pointer"
                        :href="card.ProgramLink"
                        target="_blank"
                      >
                        Sığorta təminatı</a
                      >
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div> -->

          <!-- Clinic, Pharm -->
          <div class="row" style="margin-top: 10px">
            <div class="col-12">
              <table>
                <tr>
                  <td class="icon-cell">
                    <span>
                      <i class="fa fa-hospital-o" aria-hidden="true"></i>
                    </span>
                  </td>
                  <td style="">
                    <a
                      class="ecard-a"
                      style="cursor: pointer"
                      :href="card.ClinicLink"
                      target="_blank"
                    >
                      Klinikalar</a
                    >
                  </td>
                  <td class="icon-cell">
                    <span>
                      <i class="fa fa-medkit" aria-hidden="true"></i>
                    </span>
                  </td>
                  <td style="">
                    <a
                      class="ecard-a"
                      style="cursor: pointer"
                      :href="card.PharmLink"
                      target="_blank"
                    >
                      Apteklər</a
                    >
                  </td>
                  
                  <td style="padding-left: 10px">
                    <a
                      style="cursor: pointer"
                      :href="'tel:+994123770909'"
                      v-if="card.HasDron"
                    >
                      <img src="dron.png" alt="" style="width: 80px" />
                    </a>
                  </td>
                </tr>
              </table>
            </div>
          </div>

          
        </div>

        
      </div>
      
    </div>
    <div v-if="found === 0" style="text-align: center; padding-top: 20px">
      <i class="fa fa-clock-o fa-4x" style="margin-left: -30px"></i>
      <br />
      <span style="font-weight: bold">Xahiş edirik gözləyin</span>
    </div>
    <div v-if="found === 2" style="text-align: center; padding-top: 20px">
      <i class="fa fa-ban fa-4x" style="color: red; margin-left: -30px"></i>
      <br />
      <span style="font-weight: bold">Aktiv sığorta kartı tapılmadı</span>
    </div>
  </div>
</template>

<script>
import { GetCard, GetProgramPdf, GetCardMoc } from "../services/ecard";
export default {
  props: {
    id: String,
  },
  components: {},

  data() {
    return {
      card: {},
      fullname: [],
      found: 0,
    };
  },
  methods: {
    getCard() {
      this.found = 0;
      GetCard(this.id).then((response) => {
        if (response.data) {
          this.card = response.data;
          this.found = 1;
        } else {
          this.found = 2;
        }
      });
    },
    getCardMoc() {
      this.found = 0;
      this.card = GetCardMoc(this.id);
      this.fullname = this.card.Name.split(" ");
      this.found = 1;
    },

    getPdf(link, title) {
      var fileURL = window.URL.createObjectURL(new Blob([link]));
      var fileLink = document.createElement("a");

      fileLink.href = fileURL;
      fileLink.setAttribute("download", title + "_" + this.id + ".pdf");
      document.body.appendChild(fileLink);

      fileLink.click();
    },
  },
  mounted() {
    this.getCard();
  },
};
</script>

<style>
.header-div {
  background: white;
}
.header-img {
  margin-left: auto;
  margin-right: auto;
  height: 200px;
  display: block;
}
.ecard {
  width: 400px;
}
.inside {
  width: 340px;
  top: 200px;
  /* height: 651px; */
  left: 40px;
}

.icon-cell {
  width: 40px;
  color: #a92a24;
  text-align: center;
  font-size: 20px;
}

.title-margin {
  margin-top: -8px;
}
.sur-name {
  font-size: 11pt;
  font-weight: bold;
  color: #25587d;
}
.full-name {
  font-size: 16pt;
  /* font-family: Verdana; */
  font-weight: bold;
  color: #bb1a1a;
}
.ecard-fullanme-title {
  font-size: 10pt;
  font-weight: 500;
  color: #bb1a1a;
}
.ecard-a {
  color: #bb1a1a;
  font-size: 10pt;
  font-weight: bold;
}
.ecard-title {
  font-size: 10pt;
  font-weight: 500;
}
.ecard-value {
  font-size: 10pt;
  /* font-family: Verdana; */
  font-weight: bold;
  color: #5b5b5b;
}
</style>
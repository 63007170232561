// const api = "https://medical-ecard-api.progress-ims.com/api";
const api = "https://ecard-agroup-api.progress-ims.com/m";
function ShowLink(value) {
  var text = value;
  var exp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
  var text1 = text.replace(exp, "<a href='$1'>$1</a>");
  var exp2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
  return text1.replace(exp2, '$1<a target="_blank" href="http://$2">$2</a>');
}

function HtmlNewLine(value) {
  return value.replace(/(?:\r\n|\r|\n)/g, "<br />");
}

function Round2(num) {
  return Math.round(num * 100) / 100;
}

function DaysBetween(dt1, dt2) {
  var oneday = 1000 * 60 * 60 * 24;
  dt1 = new Date(dt1);
  dt2 = new Date(dt2);
  var diff = Math.round(dt2.getTime() - dt1.getTime()) / oneday;

  var res = diff.toFixed(0);
  return res;
}

function DateToString(dt) {
  dt = new Date(dt);
  let date = ("0" + dt.getDate()).slice(-2);

  let month = ("0" + (dt.getMonth() + 1)).slice(-2);

  let year = dt.getFullYear();

  return year + "-" + month + "-" + date;
}

function DateTimeToString(dt) {
  dt = new Date(dt);
  let date = ("0" + dt.getDate()).slice(-2);

  let month = ("0" + (dt.getMonth() + 1)).slice(-2);

  let year = dt.getFullYear();

  let hour = dt
    .getHours()
    .toString()
    .padStart(2, "0");

  let minutes = dt
    .getMinutes()
    .toString()
    .padStart(2, "0");

  let seconds = dt
    .getSeconds()
    .toString()
    .padStart(2, "0");

  return (
    year + "-" + month + "-" + date + " " + hour + ":" + minutes + ":" + seconds
  );
}

function LangText(text, lng) {
  if (!text) return;
  var arr = text.split("//");
  var res;
  if (arr.length === 1) res = text;
  else res = arr[lng];

  return res;
}

export {
  ShowLink,
  HtmlNewLine,
  api,
  Round2,
  DaysBetween,
  DateTimeToString,
  DateToString,
  LangText,
};
